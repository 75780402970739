
const profile = {
  es: {
    profile_helper: 'Tu perfil tiene todas estas opciones para certificar y firmar documentos ',
    profile_title: 'Perfil',
    profile_tab: 'Datos Generales de uso',
    signature_tab: 'Imagen o texto',
    fingerprint_tab: 'Huella',
    options_tab: 'Opciones',
    formats_tab: 'Formatos de Firma',
    preprocess_tab: 'Configuración PDF',
    user: 'Usuario',
    id: 'ID',
    email: 'Correo',
    phone: 'Teléfono',
    address: 'Dirección',
    country: 'País',
    department: 'Estado',
    city: 'Ciudad',
    serial: 'Serial Certificado',
    expire: 'Valido hasta',
    download_certificate: 'Ver/Descargar Certificado',
    delete_credentials: 'Eliminar credenciales de firma',
    change_password: 'Gestionar contraseña',
    load_signature: 'Cargar nueva firma gráfica',
    allowed_values: '.jpe, .jpg, .jpeg, .gif, .png',
    create_signature: 'Crear una nueva imagen de firma con su nombre',
    loading_fingerprint: 'Conectando con el Fingerprint, por favor espere...',
    found_fingerprint: 'Lector cargado correctamente, por favor coloque la huella para actualizarla',
    not_found_fingerprint: 'Lector no encontrado, por favor conéctelo y recargue la página',
    certificate_type: 'Certifica tus documentos .PDF. Si lo haces nadie más podrá firmarlos.',
    server: 'Escoge el servidor de sello de tiempo de tu entidad de confianza.',
    chooseGraphics: 'Escoge tu firma gráfica',
    chooseOperation: 'Escoge qué tipo de firma quieres usar',
    signature_type: 'Tipo de firma gráfica:',
    optionReason: 'Escribe la indicación que quieras incluir debajo de tu firma gráfica. Si usas firma digital te aparecerá quien va a firmar el documento.',
    publish: 'Publicar documento: Los archivos quedarán con un código QR en la parte inferior derecha de cada hoja. La persona  que escanee el código tendrá acceso al documento.',
    showCertModal: 'No mostrar un cuadro de dialogo para importar certificados al iniciar sesión',
    showShareModal: 'No mostrar un cuadro de dialogo para compartir archivos cada que se firma un documento en el previsualizador',
    chooseDocsView: 'Escoger la vista predeterminada para documentos',
    chooseDocsViewList: 'Lista de documentos',
    chooseDocsFolderList: 'Imagenes',
    edit_password: 'Editar clave',
    new_password: 'Nueva clave',
    confirm_password: 'Confirmar clave',
    signature_modal: 'Imagen o texto',
    generate_graphic: 'Genera tu firma gráfica',
    write_name: 'Tu nombre',
    write_name_lb: 'Escribe tu nombre',
    font: 'Fuente',
    preview: 'Vista  previa:',
    noimage: 'No hay imagen cargada',
    usedOperations: 'Número de Firmas Realizadas',
    totalOperations: 'Número de Firmas autorizadas',
    unlimited: 'Sin Límite',
    availableOperations: 'Número de Firmas Disponibles',
    usedDocs: 'Número de Documentos firmados',
    totalDocs: 'Número de Documentos autorizados',
    availableDocs: 'Número de Documentos Disponibles',
    signAllP7: 'Puedes firmar cualquier tipo de archivo digital utilizando el formato estándar de criptografía de clave pública PCKS #7. Si lo haces con firma electrónica el archivo quedará firmado con extensión .ZIP.  Si lo firmas digitalmente tu archivo quedara firmado con extensión .P7Z',
    fillpdfLabel: 'Puedes firmar tus formularios o plantillas .PDF. Si habilitas esta opción podrás diligenciar los campos de la plantilla antes de previsualizar el documento.',
    confirmDelete: 'Si elimina sus credenciales no podrá realizar firma digital hasta que importe un nuevo certificado, ¿Está seguro?',
    currentImage: 'Firma gráfica actual',
    combinePDF: 'Combina los documentos en un solo archivo PDF',
    minMaxError: 'El tamaño mínimo debe ser menor o igual al máximo',
    certInfo: 'Información del certificado digital en uso',
    operationsLabel: 'Licenciamiento',
    installConnector: 'Si cuentas con sistema operativo Windows, captura tu huella dactilar con un lector de huella (Digital Persona 4500) e instala nuestro componente Netco Conector en https://demos.netco.la/netcoHUB) ',
    settings: 'Configura tu firma',
    appSettings: 'Configura tu aplicación',
    additionalSettings: 'Configuración adicional',
    save: 'Guardar',
    descriptionSettings: 'Tu perfil tiene todas estas opciones para certificar y firmar documentos',
    selectCertificate: 'Seleccionar otro certificado',
    send: 'Enviar',
    importCertificate: 'Importa o selecciona tu certificado',
    noCertificate: 'No tienes ningún certificado activo en tu cuenta de Netco Signer, puedes utilizar este botón para seleccionar o importar un certificado digital',
    descriptionCertificate: 'Este es tu certificado digital, puedes utilizarlo para realizar firma digital',
    whyFingerprintTitle:'¿Porqué debería tener una huella?',
    whyFingerprintDescription:'Puedes utilizar tu huella como firma gráfica en un documento PDF, adicionalmente puedes usarla para autenticarte de forma segura en Netco Signer. Para conocer más información contacta a tu administrador.',
    deleteUser: 'Eliminar usuario',
    deleteUserDescription:'Estás a punto de eliminar tu cuenta de Netco Signer. Recuerda que esta acción es irreversible y no podrás restaurar tu cuenta ni recuperar los datos asociados.',
    deleteUserTitle:'Eliminar cuenta de Netco Signer',
    defaultTransfer:'Activar transferencias por defecto',
    defaultRemoveFormFields: 'Remover campos de formulario por defecto.',
    setCellPhone: 'Configura tu celular para recibir notificaciones.',
    setCellPhone1: 'Aquí puedes configurarlo fácilmente. Selecciona tu código de área y escribe tu número de celular.',
    cellPhone: 'Celular',
    codeArea: 'Código de área',
    currentCellPhone: 'Este es el número de celular actualmente configurado para recibir notificaciones.',
    readAgreementPersonal: 'Puedes leer el acuerdo de tratamiento de información personal.'
  },
  en: {
    profile_helper: 'Your profile has all this options to certify and sign documents',
    profile_title: 'Profile',
    profile_tab: 'General usage data',
    signature_tab: 'Signature',
    fingerprint_tab: 'Fingerprint',
    options_tab: 'Options',
    formats_tab: 'Signature Formats',
    preprocess_tab: 'PDF Configuration',
    user: 'User',
    id: 'ID',
    email: 'Email',
    phone: 'Phone number',
    address: 'Address',
    country: 'Country',
    department: 'State',
    city: 'City',
    serial: 'Certificate Serial',
    expire: 'Not Valid After',
    download_certificate: 'See/download certificate',
    delete_credentials: 'Remove signature credentials',
    change_password: 'Manage password',
    load_signature: 'Load new graphic signature',
    allowed_values: '.jpe, .jpg, .jpeg, .gif, .png',
    create_signature: 'Create a new image signature with your name',
    loading_fingerprint: 'Connecting with the fingerprint, please wait...',
    found_fingerprint: 'Ready, please put your fingerprint to update it',
    not_found_fingerprint: 'Reader not found, please connect it and reload the page',
    certificate_type: 'Do you want to certificate your signed documents? If you do, no one else can sign them after you',
    server: 'Time stamp server:',
    chooseGraphics: 'Choose your graphic signature',
    chooseOperation: 'Choose the type of signature you watn to use',
    signature_type: 'Type of signature:',
    optionReason: 'Type the text you want to include below your graphic signature. Also if you use digital signature you will see Signed By',
    publish: 'Publish document: The files will have a QR code on the bottom right of each page. The person scanning the code will have access to the document.',
    showCertModal: 'Don"t Show dialog to import certificates on login',
    showShareModal: 'Don"t Show dialog to share files in the pdf previewer',
    chooseDocsView: 'Choose default view for documents',
    chooseDocsViewList: 'Document list',
    chooseDocsFolderList: 'Thumbprints list',
    edit_password: 'Edit password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    signature_modal: 'Graphic signature',
    generate_graphic: 'Generate your graphic signature',
    write_name: 'Your name',
    write_name_lb: 'Write your name',
    font: 'Font',
    preview: 'Preview:',
    noimage: 'No image loaded',
    usedOperations: 'Used signature operations',
    totalOperations: 'Total signature operations',
    unlimited: 'Unilimited',
    availableOperations: 'Available signature operations',
    usedDocs: 'Signed Documents',
    totalDocs: 'Limit of documents',
    availableDocs: 'Available documents',
    signAllP7: 'Sign all documents by using PKCS7',
    fillpdfLabel: 'Fill pdf template before signing',
    confirmDelete: 'If you delete your credentials you won"t be able to use Digital Signature until you import a new certificate, ¿Are you sure?',
    currentImage: 'Current signature image',
    combinePDF: 'Combine PDFs',
    minMaxError: 'Minimun length must be lower or equal than max length',
    certInfo: 'Digital Certificate Info',
    operationsLabel: 'Signature Operations Balance',
    installConnector: 'To use your fingerprint you need Netco Conector (You may find it on https://demos.netco.la/netcoHUB)',
    settings: 'Set up your signature',
    appSettings: 'Set up your application',
    additionalSettings: 'Additional settings',
    save: 'Save',
    descriptionSettings: 'Your profile has all these options to certify and sign documents',
    selectCertificate: 'Select another certificate',
    send: 'Send',
    importCertificate: 'Import or select your certificate',
    noCertificate: 'You dont have any active certificate in your Netco Signer account, you can use this button to select or import a digital certificate',
    descriptionCertificate: 'This is your digital certificate, you can use it to make a digital signature',
    whyFingerprintTitle:'¿Why a fingerprint?',
    whyFingerprintDescription:'You can use your fingerprint as a graphic image on a PDF document. You can also use it to authenticate to Netco Signer. To know more please contact your administrator.',
    deleteUser: 'Delete user',
    deleteUserDescription:'You are about to delete your Netco Signer account. Remember that this action is irreversible and you will not be able to restore your account or recover the associated data.',
    deleteUserTitle:'Delete Netco Signer Account',
    defaultTransfer:'Enable transfers by default',
    defaultRemoveFormFields: 'Remove default form field.', 
    setCellPhone: 'Set your cell phone to receive notifications.',
    setCellPhone1: 'You can easily set it up here. Select your area code and enter your cell phone number.',
    cellPhone: 'Cellphone',
    codeArea: 'Area code',
    currentCellPhone: 'This is the cell number currently set to receive notifications.',
    readAgreementPersonal: 'You can read the personal information processing agreement.'
  }
};
export default profile;
