import React from 'react';
import { connect } from "react-redux";
import { reset, change } from "redux-form";
import { push } from 'connected-react-router';
import './profile.css';
import TabsHelper from "../Helpers/TabsHelper";
import NewProfileInfo from './NewProfileInfo'
import Fingerprint from './Fingerprint';
import getUserInfo from "../../actions/profile/getUserInfo";
import getImageTypesList from "../../actions/profile/getImageTypesList";
import getTimestampsInfo from "../../actions/profile/getTimestampsInfo";
import updateUserPassword from "../../actions/profile/updateUserPassword";
import updateUserFingerprint from "../../actions/profile/updateUserFingerprint";
import PrivateRoute from "../Root/PrivateRoute";
import profile from "../../const/profile";
import GraphicSignatureForm from "./Signature/GraphicSignatureForm";
import showOptionsSignature from '../../actions/info/showOptionsSignature';
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import logoutUser from "../../actions/auth/logoutUser";
import deleteUser from "../../actions/admin/deleteUser";
import updateUserSettings from '../../actions/profile/updateUserSettings';
import { renderSelectAreaCode } from '../Helpers/utils';


class Profile extends React.Component {

  componentDidMount() {
    this.props.getUserInfo();
    this.props.getImageTypesList();
    this.props.getTimestampsInfo();
  }

  updateCellPhone = (info) => {
    const cellPhone = info.cellPhone && info.areaCode?{cellPhone:'+'+info.areaCode+info.cellPhone}:{cellPhone:''};
    this.props.updateUserSettings(cellPhone);
  }

  tabProps() {
    return (
      {
        number: 3,
        titles: [
          profile[this.props.language].profile_tab,
          profile[this.props.language].signature_tab,
          profile[this.props.language].fingerprint_tab
        ],
        keys: [
          'profile',
          'sign',
          'fingerprint'
        ],
        content: [
          NewProfileInfo,
          GraphicSignatureForm,
          Fingerprint
        ],
        tabProps: [
          {
            language: this.props.language, user: this.props.user, updateUserPassword: this.props.updateUserPassword, 
            showOptionsSignature: this.props.showOptionsSignature, push: this.props.push, modalShow: this.props.modalShow, modalDismiss: this.props.modalDismiss,
            logoutUser: this.props.logoutUser, deleteUser: this.props.deleteUser,
            updateCellPhone: this.updateCellPhone, renderSelectAreaCode
          },
          { finishUpImageSignature: this.finishUpImageSignature },
          { language: this.props.language, user: this.props.user, updateUserFingerprint: this.props.updateUserFingerprint },
        ]
      }
    );
  };

  finishUpImageSignature = async () => {
    await this.props.getUserInfo();
    this.resetForm();
  }

  resetForm = () => {
    const form = 'graphic_signature_form';
    this.props.dispatch(reset(form));
    this.props.dispatch(change(form, 'base64Image', this.props.user.image));
  };

  render() {
    return (
      <div className="card-container h-100 p-20 tabs-container">
        <TabsHelper activeKey={'profile'} {...this.tabProps()} onChange={this.resetForm} />
      </div>
    );
  };

}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    user: state.user
  };
};

export default PrivateRoute(connect(mapStateToProps, { getImageTypesList, getTimestampsInfo, getUserInfo, updateUserSettings, 
  updateUserPassword, updateUserFingerprint,  showOptionsSignature, push, modalShow, modalDismiss, logoutUser, deleteUser
})(Profile));
