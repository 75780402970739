import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Table, Typography, Collapse } from "antd";
import DropZoneField from "../Helpers/DropzoneField";
import { reduxForm } from "redux-form";
import verifyFiles from '../../actions/verifications/verifyFiles';
import cleanVerifiedFiles from '../../actions/verifications/cleanVerifiedFiles';
import modalShow from '../../actions/modal/modalShow';
import logoutUser from "../../actions/auth/logoutUser";
import { LOADING_MODAL, VERIFICATIONS_MODAL } from "../Feedback/Modal/types";
import PrivateRoute from "../Root/PrivateRoute";
import verify from "../../const/verify";
import general from "../../const/general";
import modalDismiss from "../../actions/modal/modalDismiss";
import handleBase64ZipResponse from "../../actions/helpers/handleBase64ZipResponse";
import { checkMaxFileSize, getCustomLogos, getCustomMessages, getLoginPath } from "../Helpers/utils";
import signature from "../../const/signature";
import moment from "moment";
import TagLabel from "../Helpers/TagLabel";
import isLoggedIn from "../../actions/auth/isLoggedIn";
import auth from "../../const/auth";
import { push } from 'connected-react-router';
import { ZOOM_MODAL } from '../Feedback/Modal/types';
import { INFO_SHOW } from '../../actions/types';
import infoPayload from '../../actions/helpers/payloadHandlers/infoPayload';
import collapseUpIcon from '../../resources/img/NewIcons/sign/collapseUpIcon.svg';
import collapseDownIcon from '../../resources/img/NewIcons/sign/collapseDownIcon.svg';
import { ReactComponent as IconLogoqr } from '../../resources/img/NewIcons/verification/LogoStepQR.svg';
import { ReactComponent as RocketIcon } from '../../resources/img/NewIcons/verification/rocket.svg';
import { ReactComponent as StepIcon1 } from '../../resources/img/NewIcons/verification/step1.svg';
import { ReactComponent as StepIcon2 } from '../../resources/img/NewIcons/verification/step2.svg';
import { ReactComponent as StepIcon3 } from '../../resources/img/NewIcons/verification/step3.svg';
import { ReactComponent as StepQRIcon1 } from '../../resources/img/NewIcons/verification/step1qr.svg';
import iconAlertTriangle from '../../resources/img/NewIcons/verification/alert-triangle.svg';
import iconCloseCircle from '../../resources/img/NewIcons/verification/close-circle.svg';
import './verification.css'

class NewVerification extends React.Component {

  maxZoom = 500;
  zoomIncrement = 50;
  state = { formFile: [], maxFileSize: 0, loggedIn: false, zoom: 100.0, pageNumber: 1, file: null, ActiveCollapse: true, ActiveCollapse2: false, showAlert: true };

  componentDidMount() {
    this.props.isLoggedIn([(data) => {
      this.setState({ maxFileSize: data.auth.maxFileSize, loggedIn: data.auth.loggedIn });
    }]);

    const urlInfo = this.props.search.substring(1);
    if (urlInfo) {
      this.getFile(urlInfo)
    }
  }

  getFile = async (urlInfo) => {
    const urlArray = urlInfo.split('&');
    const jsonURL = {}
    urlArray.forEach(item => {
      const arrayItem = item.split('=')
      jsonURL[arrayItem[0]] = arrayItem[1]
    });
    const urlGetFile = window.netcourlid + '/viewNewDoc?' + urlInfo
    fetch(urlGetFile)
      .then(response => response.blob())
      .then(blob => {
        if (blob && blob.type && blob.size > 0) {
          jsonURL.fileName = jsonURL.fileName.replaceAll('+', ' ')
          const file = new File([blob], jsonURL.fileName, { type: 'application/' + jsonURL.fileName.slice((jsonURL.fileName.lastIndexOf(".") - 1 >>> 0) + 2) });
          this.setState({ formFile: [...this.state.formFile, ...[file]] }, this.onSubmit)
        } else {
          this.props.dispatch({ type: INFO_SHOW, payload: infoPayload('error', verify[this.props.language].getFileError) });
        }
      })
  }

  handleOnDrop = (files, onChange) => {
    this.setState({ formFile: [...this.state.formFile, ...files] }, () => onChange(files));
    if (this.props.verifiedFilesList.entries.length > 0)
      this.props.cleanVerifiedFiles();
  };

  handleDeleteFile = (value) => {
    this.setState({ formFile: this.state.formFile.filter(item => item.name !== value) });
  }

  onSubmit = () => {
    if (checkMaxFileSize(this.state.maxFileSize, this.props.language, this.props.dispatch, this.state.formFile)) {
      this.onLoading();
      this.props.verifyFiles(this.state.formFile).then(this.props.modalDismiss);
    }
  };

  onLoading = () => {
    this.props.modalShow({
      modalType: LOADING_MODAL,
      modalProps: { footer: null },
      componentProps: { text: verify[this.props.language].verifyingMessage }
    });
  }

  onShow = (record) => {
    this.props.modalShow({
      modalType: VERIFICATIONS_MODAL,
      modalProps: { footer: null, title: verify[this.props.language].modal_title },
      componentProps: { language: this.props.language, entry: record, handleResponse: this.props.handleBase64ZipResponse, downloadCert: this.downloadCert }
    });
  };

  zoomInPage = () => {
    const zoomIn = (parseFloat(this.state.zoom) + this.zoomIncrement).toFixed(2)
    if (zoomIn <= this.maxZoom)
      this.setState({ zoom: zoomIn }, this.zoomPage)
  }

  zoomOutPage = () => {
    const zoomOut = (parseFloat(this.state.zoom) - this.zoomIncrement).toFixed(2)
    if (zoomOut > 0)
      this.setState({ zoom: zoomOut }, this.zoomPage)
  }

  handleChangeZoom = (event) => {
    const regex = new RegExp("^[0-9%.]+$");
    if (regex.test(event.target.value)) {
      let percentage = event.target.value.replaceAll('%', '');
      if ((percentage.match(/\./g) || []).length < 2 && percentage !== '.' && percentage <= this.maxZoom) {
        this.setState({ zoom: percentage }, this.zoomPage)
      }
    }
  }


  zoomPage = () => {

    /* const password = this.state.passwords;
    let pdfPass = null;
    if (password)
      pdfPass = password[this.props.signatureFiles.entries[this.state.currentFile].name]; */

    this.props.modalShow({
      modalType: ZOOM_MODAL,
      modalProps: { footer: null },
      componentProps: {
        language: this.props.language, entries: [{ content: this.state.file }],
        pageNumber: this.state.pageNumber, currentFile: 0,
        zoom: this.state.zoom, zoomInPage: this.zoomInPage, zoomOutPage: this.zoomOutPage,
        handleChangeZoom: this.handleChangeZoom, pdfPass: ''
      }
    });
  }

  viewFile = (file) => {
    const blob = new Blob([file]);
    this.setState({ file: blob }, this.zoomPage);
  }

  downloadCert = (cert, name) => {
    
    handleBase64ZipResponse(cert, name);
  }

  columns = () => {
    return [
      {
        title: verify[this.props.language].document_column,
        render: (text, record) => (record.fileName[0]),
        key: 'document'
      },
      {
        title: verify[this.props.language].state_column,
        align: 'center',
        width: '130px',
        render: (text, record) => (record.valid === true ? <TagLabel type={'Success'} text={verify[this.props.language].signatureOK} /> :
          <TagLabel type={'Danger'} text={verify[this.props.language].signatureError} />),
        key: 'status'
      },
      {
        title: verify[this.props.language].signature_date,
        className: 'mobile-hide',
        align: 'center',
        render: (text, record) => (record.signatureDate ? moment(record.signatureDate).format('MMM D,YYYY') : ''),
        key: 'signatureDate'
      },
      {
        title: general[this.props.language].actions,
        key: 'action',
        render: (text, record) => (
          <span>
            <Button style={{ width: 'auto' }} type={'primary middle-blue rounded-sm'} htmlType={'button'} onClick={() => this.onShow(record)}>
              {verify[this.props.language].details}
            </Button>
          </span>
        )
      }];
  };

  table = () => {
    if (this.props.verifiedFilesList.entries.length > 0)
      return (
        <Table
          columns={this.columns()}
          dataSource={this.props.verifiedFilesList.entries}
          size={'small'}
          rowKey={() => Math.random()}
        />
      );
  };

  onChangePanel = (panel) => {
    if (panel === 1) {
      const ActiveCollapse = !this.state.ActiveCollapse
      this.setState({ ActiveCollapse, ActiveCollapse2: ActiveCollapse ? false : this.state.ActiveCollapse2 })
    } else {
      const ActiveCollapse2 = !this.state.ActiveCollapse2
      this.setState({ ActiveCollapse2, ActiveCollapse: ActiveCollapse2 ? false : this.state.ActiveCollapse })
    }
  };

  renderPanelHeader = (Message, Icon) => {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      {Icon}
      <Typography className='text-title size18'>
        {Message}
      </Typography>
    </div>
  };
  //TODO: Refactor
  getAccordionChildren1=()=>
  {
    return [
      {
        key: '1',
        label: this.renderPanelHeader(verify[this.props.language].firstStep, <RocketIcon style={{ marginRight: 11 }} />),
        children: <Row>
          <Col xs={24} xl={8} className='flex-col' >
            <Row>
              <Col xs={24} xl={8} style={{ textAlign: 'center' }} >
                <StepIcon1 />
              </Col>
              <Col xs={24} xl={16} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].digitalinfo_veri_one}</Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={8} className='flex-col'>
            <Row>
              <Col xs={24} xl={8} style={{ textAlign: 'center' }} >
                <StepIcon2 />
              </Col>
              <Col xs={24} xl={16} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].info_veri_two}</Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={8} className='flex-col'>
            <Row>
              <Col xs={24} xl={8} style={{ textAlign: 'center' }} >
                <StepIcon3 />
              </Col>
              <Col xs={24} xl={16} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].info_veri_three}</Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      }];
  };

  //TODO: Refactor
  getAccordionChildren2=()=>
  {
    return [
      {
        key: '1',
        label: this.renderPanelHeader(verify[this.props.language].SecondStep, <IconLogoqr style={{ marginRight: 11 }} />),
        children: <Row>
          <Col xs={24} xl={8} className='flex-col' >
            <Row>
              <Col xs={24} xl={8} style={{ textAlign: 'center' }} >
                <StepQRIcon1 />
              </Col>
              <Col xs={24} xl={16} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].qrinfo_veri_one}</Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={8} className='flex-col'>
            <Row>
              <Col xs={24} xl={24} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].info_veri_two}</Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={8} className='flex-col'>
            <Row>
              <Col xs={24} xl={8} style={{ textAlign: 'center' }} >
                <StepIcon3 />
              </Col>
              <Col xs={24} xl={16} style={{ paddingLeft: 20, paddingTop: 15 }} >
                <Typography>{verify[this.props.language].info_veri_three}</Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      }];
  };

  render() {
    const messages = getCustomMessages(this.props.language)
    const sizeLabel = '(' + signature[this.props.language].select_files + ' ' + Math.round(this.state.maxFileSize / 1024000) + 'MB )';
    return (
      <div style={{ margin: '0px 46px 0px 32px' }}>
        {!this.props.session && <img className='logo-login center' style={{ marginTop: 10, marginBottom: 50 }} alt={''} src={getCustomLogos().base64LogoDark} />}
        {this.state.showAlert && <div style={{ display: 'flex', padding: 20, background: '#FFAA0029', borderRadius: 8 }}>
          <img src={iconAlertTriangle} alt='alert' />
          <div style={{ marginLeft: 20 }}>
            <Typography className='text-title size18' style={{ color: '#DB8B00' }}>{verify[this.props.language].infoImportant}</Typography>
            <Typography className='size12' style={{ color: '#703C00' }} >{messages.messageVerify}</Typography>
          </div>
          <button className='unstyled-button right' onClick={() => this.setState({ showAlert: false })}><img src={iconCloseCircle} alt='iconCloseCircle' /></button>
        </div>}

        <Collapse accordion onChange={() => this.onChangePanel(1)} className='mt-40 mb-10 collapse-verify ' activeKey={this.state.ActiveCollapse ? '1' : '2'} expandIcon={({ isActive }) => isActive ? <img alt={''} src={collapseUpIcon} /> : <img alt={''} src={collapseDownIcon} />}
          expandIconPosition={'end'} defaultActiveKey={['1']} items={this.getAccordionChildren1()} >
        </Collapse>
        <Collapse accordion onChange={() => this.onChangePanel(2)} className='mt-40 mb-10 collapse-verify' activeKey={this.state.ActiveCollapse2 ? '1' : '2'} expandIcon={({ isActive }) => isActive ? <img alt={''} src={collapseUpIcon} /> : <img alt={''} src={collapseDownIcon} />}
          expandIconPosition={'end'} defaultActiveKey={['2']} items={this.getAccordionChildren2()}>
        </Collapse>

        <Typography className='size15' style={{ marginTop: '20px' }}>
          <b> {verify[this.props.language].moreinfo} </b> <br />
          {verify[this.props.language].moreinfo2}
          <Button className='link size15' style={{ padding: '1px 0px 0px 4px', margin: 0 }} onClick={() => (window.open('https://help.netcosigner.com/manual/verificar/', '_blank'))}>
            {verify[this.props.language].morefromHere}
          </Button>
        </Typography>

        <Row style={{ marginTop: '30px' }}>
          <Col span={24}>
            <DropZoneField formFile={this.state.formFile} multiple={true} handleOnDrop={this.handleOnDrop} label={sizeLabel}
              deleteCard={this.handleDeleteFile} viewFile={this.viewFile}
            />
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: '15px' }}>
            <Button className='text-title size12 border-button' onClick={this.onSubmit} disabled={this.state.formFile.length > 0 ? '' : 'disabled'}
              style={{ width: 159, height: 32 }}>
              {verify[this.props.language].verify}
            </Button>
          </Col>
        </Row>
        <Row>
          {this.table()}
        </Row>
        {!this.state.loggedIn && <Row>
          <p className={'link'}
            onClick={() => this.props.push(getLoginPath(this.props.search))}>{auth[this.props.language].backbtn}</p>
        </Row>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    verifiedFilesList: state.verifiedFilesList,
    search: state.router.location.search,
    session: state.session.hasSession
  };
};

export default PrivateRoute(connect(mapStateToProps, {
  logoutUser, handleBase64ZipResponse, verifyFiles,
  cleanVerifiedFiles, modalShow, modalDismiss, isLoggedIn, push
})(reduxForm({ form: 'verifications_form' })(NewVerification)));
