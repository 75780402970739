import { ArrowUpOutlined, FolderOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip, Dropdown, Menu, Typography, Button, Select } from "antd";
import Text from "antd/es/typography/Text";
import React from "react";
import general from "../../const/general";
import { pdfjs } from "react-pdf";
import { LOAD_END, LOAD_START } from "../../actions/types";
import modalShow from "../../actions/modal/modalShow";
import { METADATA_MODAL, PDF_PASSWORD_MODAL, CONFIRM_TRANSFER_MODAL } from "../Feedback/Modal/types";
import modalDismiss from "../../actions/modal/modalDismiss";
import signature from "../../const/signature";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import folder from "../../resources/img/folder.svg";
import pdf from '../../resources/img/pdf.svg';
import { Checkbox } from 'antd';
import up from '../../resources/img/up.svg';
import templatesLanguage from "../../const/templates";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";
import auth from '../../const/auth';
import newBase64Logo from '../../resources/img/new-logo-menu.svg'
import newBase64LogoDark from '../../resources/img/new-logo-login.svg'
import getBase64File from "../../actions/helpers/getBase64File";
import dropDownIcon from '../../resources/img/NewIcons/dropdown.svg';
import certificateMenuIcon from "../../resources/img/NewIcons/siderMenu/certificate-menu.svg"
import { CERTIFICATES_ROUTE } from '../Root/routes';
import layout from '../../const/layout';
import whiteFile from '../../resources/img/NewIcons/whiteFile.svg'
import { ReactComponent as FileIcon } from '../../resources/img/NewIcons/fileIcon.svg';
import trashIcon from "../../resources/img/NewIcons/trashIcon.svg";
import signDocIcon from "../../resources/img/NewIcons/sign/signDocIcon.svg";
import closeIcon from "../../resources/img/NewIcons/sign/close-circle.svg";
import netcopkiIcon from '../../resources/img/NewIcons/certificates/netcoPKI.svg'
import gseIcon from '../../resources/img/NewIcons/certificates/gse.svg'
import andesIcon from '../../resources/img/NewIcons/certificates/andes.svg'
import searchIcon from '../../resources/img/NewIcons/search.svg';
import Form from "antd/es/form/Form";
import load_files from "../../const/load_files";
import signed_files from '../../const/signed_files';
import loadingCall from "../../actions/helpers/loadingCall";
import netcoServer from "../../apis/netcoServer";

export const maxHeight = 600;
export const maxWidth = 900;
export const signerHelpURL = 'https://help.netcosigner.com/manual/firmar';
export const sGrid = 576, mGrid = 768, lGrid = 992, minW = 1024, xlGrid = 1200;
export const siderWidth = 227;
export const drawerWidthOptSig = 413;
export const minHeightMenuBottom = 887;

export function renderIDPS(idps, search, userName, message) {
    if (idps && idps.length > 0) {
        let hint = '';
        if (userName)
            hint = '&login_hint=' + userName;
        return idps.map((idp) => {
            const jsonIDP = JSON.parse(idp);
            return <Form.Item key={jsonIDP.name} style={{ height: '56.32px' }}>
                <Button className={'idps'} htmlType="button" onClick={() => {
                    window.location = jsonIDP.url + hint + "&state=" + encodeURIComponent(search + "&state=" + jsonIDP.id);
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {jsonIDP.button ? <img alt='' className='idps-icon' src={'data:image/png;base64, ' + jsonIDP.button} /> : <></>}
                        <Typography className='text-idps'>{message + ' ' + jsonIDP.id}</Typography>
                    </div>
                </Button>
            </Form.Item>
        });
    }
}

export function renderSeparator(idps, orMessage) {
    if (idps && idps.length > 0) {
        return <Row md={4} className='margin-top' style={{ marginBottom: 31.24, alignItems: 'center' }}>
            <Col xs={6} sm={8} className='separator' />
            <Col xs={12} sm={8}><Typography className='size12' style={{ textAlign: 'center' }}>{orMessage}</Typography></Col>
            <Col xs={6} sm={8} className='separator' />
        </Row>
    }
}


export function renderCopyRight(language, cardPadding) {
    return (
        <div style={{ display: 'flex' }}>
            <Typography className='size12 mb-20' style={{ marginRight: 3, marginLeft: cardPadding }}>{layout[language].footer}</Typography>
            <a target="_blank" rel="noopener noreferrer" href='https://www.netcosigner.com/' className='text-title size12 mb-20'>{layout[language].footer1}</a>
        </div>
    )
}

export function hideMenuPath(path) {
    const hidePath = ['/signature/firmar', '/sendsign', '/locate']
    return hidePath.includes(path)
}

export function getMenuItems(items, labelList, iconClassName, pathName, onlyIcons = false) {

    return items.filter(item => item.visible).map(item => {
        const label = item.label ? item.label : labelList[item.key]
        return {
            label: onlyIcons ? '' : label,
            key: item.key,
            icon: <Tooltip title={label}><item.icon className={(pathName === item.key ? 'icon-menu ' + iconClassName : iconClassName) + (onlyIcons ? ' margin-onlyIcons' : ' margin-icons')} /></Tooltip>,
            disabled: pathName === item.key,
            type: item.type,
            children: item.children && item.children.length ? item.children : null,
            className: item.posBottom ? 'pos-bottom pos-bottom' + item.posBottom : ''
        }
    });
}

export function currencyFormat(num, currency) {
    if (!currency)
        currency = "COP";
    return currency + ' ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getValidMail(user) {
    if (!user)
        return undefined;
    const userName = user.userName, mail = user.mail;
    let wpUser = "";
    if (userName && userName !== "" && !(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userName)))
        wpUser = userName;
    if (mail && wpUser === "" && mail !== "" && !(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(mail)))
        wpUser = mail;
    return wpUser;
}

export function parseSord(sord) {
    if (!sord)
        return "";
    if (sord === 'ascend' || sord === 'descend')
        return sord;
    if (sord === 'desc')
        return 'descend';
    if (sord === 'asc')
        return 'ascend';
    return sord;
}

export function fixSord(sord) {
    if (!sord)
        return "";
    if (sord === 'asc' || sord === 'desc')
        return sord;
    if (sord === 'descend')
        return 'desc';
    if (sord === 'ascend')
        return 'asc';
    return sord;
}

export function getPath(path, search) {
    const queryParams = getQueryParams(search);
    if (queryParams && queryParams.redirect) {
        path = path + '?redirect=' + decodeURIComponent(queryParams.redirect);
        if (queryParams.uniqueID)
            path += "&uniqueID=" + queryParams.uniqueID;
        if (queryParams.userName)
            path += "&userName=" + decodeURIComponent(queryParams.userName);
        if (queryParams.hideMenu)
            path += "&hideMenu=HIDE";
        if (queryParams.logout)
            path += "&logout=" + queryParams.logout;
        if (queryParams.referenceNumber)
            path += "&referenceNumber=" + queryParams.referenceNumber;
        if (queryParams.authCode)
            path += "&authCode=" + queryParams.authCode;
        if (queryParams.option)
            path += "&option=" + queryParams.option;
    }
    return path;
}

export function getLoginPath(search) {
    return getPath('/', search);
}
export function getLoginPathFromState(state) {
    return getLoginPath(state.router.location.search);
}

export function showFull() {
    return window.innerWidth > maxWidth && window.innerHeight > maxHeight;
}

export function makeID(length) {
    if (!length)
        length = Math.floor(Math.random() * (42)) + 8;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function getQueryParams(url) {
    return url ? _.reduce(url.substring(1).split("&"),
        (result, value) => {
            let params = value.split("=");
            result[params[0]] = params[1];
            return result;
        }, {}) : {};
}

export function onlyReadOnlyFields(fields) {
    if (!fields)
        return true;
    let readOnlyCount = 0;
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field && field.readOnly)
            readOnlyCount++;
    }
    return readOnlyCount === fields.length;
}

export function getProductTotalCost(quantity, cost, tax) {
    const total = Math.max(quantity * cost, 0);
    const taxValue = tax ? total * tax.rate / 100 : 0;
    return total + taxValue;
}

export function base64ToHex(str) {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result;//.toUpperCase();
}

export function renderMessageWithLinks(originalMessage) {
    const parts = [], prefix = "https://";
    let newMessage = originalMessage, linkStartIndex = newMessage.indexOf(prefix), i = 0;
    while (linkStartIndex > -1) {
        parts.push(" " + newMessage.substring(0, linkStartIndex - 1) + " ");
        newMessage = newMessage.substring(linkStartIndex);
        const linkEndSpaceIndex = newMessage.indexOf(" ");
        const linkReturnSpaceIndex = newMessage.indexOf("\n");
        let linkEndIndex = -1;
        if (linkEndSpaceIndex > -1 && linkReturnSpaceIndex > -1)
            linkEndIndex = Math.min(linkEndSpaceIndex, linkReturnSpaceIndex);
        else
            linkEndIndex = Math.max(linkEndSpaceIndex, linkReturnSpaceIndex);
        const link = linkEndIndex > -1 ? newMessage.substr(0, linkEndIndex) : newMessage;
        parts.push(<a rel="noopener noreferrer" target={'_blank'} key={++i} href={link}>{link}</a>);
        newMessage = newMessage.substr(link.length + 1);
        linkStartIndex = newMessage.indexOf(prefix);
    }
    if (newMessage.length > 0)
        parts.push(" " + newMessage);
    return parts.map(part => part);
}

export function initializeProfileOptions(props) {
    if (props.validOperations && props.validOperations.length > 0) {
        let operation = props.user.operation;
        const foundOperations = props.validOperations.filter((validOperation) => validOperation.key === operation + '');
        if (foundOperations.length === 0)
            operation = parseInt(props.validOperations[0].key);
        props.initialize({
            ..._.pick(props.user, ['tsid', 'reason', 'previewPDF', 'mails', 'signAllPages']),
            publish: props.user.publish === 'true',
            certification: props.user.certification === 'true',
            operation: operation,
            useImage: props.user.useImage + '',
            p7z: props.user.p7z,
            fillpdf: props.user.fillpdf
        });
    }
}

async function getDocument(dispatch, base64File, password) {
    dispatch({ type: LOAD_START });
    const loadingTask = pdfjs.getDocument({ data: atob(base64File), password: password });
    await trackPromise(loadingTask.promise);
    dispatch({ type: LOAD_END });
    loadingTask.destroy();
}

export async function checkPDFPassword(file, fileName, defaultPassword, dispatch, language) {
    return new Promise(async function (resolve, reject) {
        if (!isPDF(fileName)) {
            resolve('');
            return;
        }
        const base64File = await getBase64FromFileObject(file);
        try {
            await getDocument(dispatch, base64File, defaultPassword);
            resolve('');
            return;
        }
        catch (err) {
            if (!isPDFPasswordError(err)) {
                console.log('Error en archivo: ' + fileName, err);
                dispatch({ type: LOAD_END });
                if (err.message)
                    reject(infoPayload('error', err.message));
                else
                    reject(infoPayload('error', err));
                return;
            }
        }
        while (true) {
            try {
                let password = await showPasswordModal(dispatch, language, fileName);
                try {
                    await getDocument(dispatch, base64File, password);
                    resolve(password);
                    break;
                }
                catch (err2) {
                    if (!isPDFPasswordError(err2)) {
                        dispatch({ type: LOAD_END });
                        console.error(err2);
                        reject(infoPayload('error', err2));
                        break;
                    }
                }
            }
            catch (err) {
                dispatch({ type: LOAD_END });
                reject(infoPayload('close', ''));
                break;
            }
        }
    });
}

export async function getBase64FromFileObject(file) {
    if (!file)
        return undefined;
    const fileObject = file.file;
    if (fileObject)
        return fileObject;
    if (file.path)
        return await getBase64File(file);
    return file;
}

export async function showPasswordModal(dispatch, language, fileName) {
    return new Promise(function (resolve, reject) {
        dispatch({ type: LOAD_END });
        dispatch(modalShow({
            modalType: PDF_PASSWORD_MODAL,
            modalProps: {
                onCancel: () => {
                    dispatch(modalDismiss());
                    reject();
                },
                language: language,
                footer: null,
                title: signature[language].password_for.replace('$0', fileName)
            },
            componentProps: {
                language: language, onSubmit: (values) => {
                    dispatch(modalDismiss());
                    resolve(values.password);
                }
            }
        }));
    });
}

const renderCard = (template, onChange, onEnter, onUp, checked = false) => {
    let image = folder;
    const isFolder = template.folder;
    const isUpButton = template.name === '...';
    const callback = () => onEnter(template.id);
    if (isFolder) {
        if (isUpButton)
            image = up;
    }
    else
        image = pdf;
    return <Card onDoubleClick={isFolder ? (isUpButton ? onUp : callback) : null} style={{ background: 'rgb(245,245,245)', height: '100%', padding: '20px' }}
        hoverable bodyStyle={{ padding: 0, textAlign: 'center', height: '100%' }}
    >
        <Row justify="space-around" align="middle" style={{ height: '100%' }}>
            <Col span={24}><img alt="example" src={image} style={{ width: '50px' }} /></Col>
            <Col span={24}>{!isFolder ? <Checkbox checked={checked} onChange={(e) => onChange(template.id, e.target.checked)} /> : ''}</Col>
            <Col style={{ lineHeight: "1.0" }} span={24}><Text style={{ wordWrap: 'break-word', fontSize: '12px' }}>{template.name}</Text></Col>
        </Row>
    </Card>;
};

export const renderTemplates = (language, templates, onChange, onEnter, onUp, colsLength = 4, showTitle = true, defaultValues = undefined) => {
    if (!templates || templates.length === 0)
        return;
    if (window.innerWidth < sGrid && colsLength > 2)
        colsLength = 2;
    else if (window.innerWidth < xlGrid && colsLength > 4)
        colsLength = 4;
    let i = 0, rows = [], rowsLength = 1;
    if (templates.length > colsLength)
        rowsLength = Math.ceil(templates.length / colsLength);
    for (let j = 0; j < rowsLength; j++) {
        let cols = [];
        for (let k = 0; k < colsLength && i < templates.length; k++) {
            let checked = false;
            if (defaultValues && defaultValues.includes(templates[i].id))
                checked = true
            cols.push(<Col key={'col' + k} span={24 / colsLength}>{renderCard(templates[i], onChange, onEnter, onUp, checked)}</Col>);
            i++;
        }
        rows.push(cols);
    }
    const newRows = rows.map((row, index) => <Row key={index} style={{ marginTop: '10px', marginBottom: '10px' }} gutter={{ xs: 2, sm: 10, md: 18, lg: 26 }}>{row.map((col) => col)}</Row>);
    if (showTitle)
        newRows.unshift(<Row key={'selecttemplatekey'}>{templatesLanguage[language].selectTemplate}</Row>);
    return newRows;
};

export const renderItem = (text, record, upCallBack, enterCallBack, newView) => {
    let isFolder = record.folder;
    if (!isFolder)
        isFolder = record.uid === '';
    const maxLength = 60, tooBig = text && text.length > maxLength;
    const result = tooBig ? text.substr(0, maxLength) + '...' : text;
    const item = isFolder ? renderFolder(result, record, upCallBack, enterCallBack, newView) : renderFile(result, record);
    if (tooBig)
        return <Tooltip placement={'right'} title={text}>{item}</Tooltip>;
    return item;
};

const renderFolder = (result, record, upCallBack, enterCallBack, newView = false) => {
    const isUpButton = record.name === '...';
    const callback = () => enterCallBack(record.id);
    return (
        <div onDoubleClick={isUpButton ? upCallBack : callback}>
            <p className={'link10'} style={{ MozUserSelect: 'none', WebkitUserSelect: 'none', msUserSelect: 'none' }}>
                {isUpButton ? <ArrowUpOutlined style={{ color: '#60c5ff' }} /> : <FolderOutlined style={{ marginRight: 5 }} />}
                <Text className='color-black' style={!newView ? { fontWeight: 'bold', wordBreak: 'break-all' } : { wordBreak: 'break-all' }}>   {result}</Text>
            </p>
        </div>
    );
};
export const isTemplateFolder = (selectedRows) => {
    let folder = false;
    for (let i = 0; i < selectedRows.length; i++) {
        if (selectedRows[i] && selectedRows[i].folder) {
            folder = true;
            break;
        }
    }
    return folder;
};

export const addUpButton = (stateParentId, propsParentId, entries) => {
    if (entries) {
        let upLevel = { key: 'upbutton' + stateParentId, id: propsParentId, uid: '', name: '...', folder: true };
        if (entries.length > 0) {
            if (stateParentId && entries[0].name !== '...')
                entries.unshift(upLevel);
        }
        else if (stateParentId)
            entries.push(upLevel);
    }
    return entries;
};

export const renderUploadCard = (deleteCard, text, size, id, card, viewFile) => {
    const isFile = card && card instanceof File
    const spanViewFile = isFile && viewFile ? 2 : 0;
    const renderFileName = isFile ?
        <a style={{ paddingLeft: '10px', marginBottom: 0 }} href={URL.createObjectURL(card)} download={card.name}>
            {text} <br />
        </a> : <p style={{ paddingLeft: '10px', color: 'black', marginBottom: 0 }} className='size15'>{text}</p>
    return <Row style={{ minHeight: '88px', alignItems: 'center' }}>
        <Col style={{ paddingLeft: '15px' }} xs={6} md={2}>
            <FileIcon />
        </Col>
        <Col style={{ textAlign: 'start' }} xs={16 - spanViewFile} md={20 - spanViewFile}>
            {renderFileName}
            <span style={{ paddingLeft: '10px' }} className='size12'>{Math.round((size / 1000)) + '  KB'}</span>
        </Col>
        <Col span={2}>{isFile && viewFile && text.slice((text.lastIndexOf(".") - 1 >>> 0) + 2) === 'pdf' ? <img alt={''} src={searchIcon} style={{ cursor: 'pointer' }} onClick={() => viewFile(card)} /> : <></>}</Col>
        <Col span={2}>{deleteCard ? <img alt={''} src={trashIcon} style={{ cursor: 'pointer' }} onClick={() => deleteCard(id)} /> : <></>}</Col>
    </Row>;
}

export const renderSignCard = (deleteCard, text, size, id) => {
    let shortText = text;
    if (shortText && shortText.length > 23)
        shortText = shortText.substring(0, 20) + '...';
    return <Row style={{ height: '180px', width: '180px', alignItems: 'center', paddingTop: '20px' }}>
        <Col span={24}>
            <img alt={''} src={signDocIcon} />
            {deleteCard && <img style={{ position: "absolute", top: "-38px", left: "165px", cursor: 'pointer' }} alt={''} src={closeIcon} onClick={() => deleteCard(id)} />}
        </Col>
        <Col style={{ textAlign: 'start' }} span={24}>
            <Tooltip placement={'top'} title={text}>
                <p style={{ textAlign: 'center', color: 'black', marginBottom: 0 }} className='size15'>{shortText}</p>
            </Tooltip>
            {size && <p style={{ textAlign: 'center', marginBottom: 0 }} className='size12'>{Math.round((size / 1000)) + '  KB'}</p>}
        </Col>
    </Row>;
}

const renderFile = (result, record) => {
    if (record.link) {
        return <div>
            <p className={'link10'} style={{ MozUserSelect: 'none', WebkitUserSelect: 'none', msUserSelect: 'none' }}>
                <img style={{ width: '16px', height: '20px', paddingBottom: '3px', marginLeft: '-3px', marginRight: 10 }} alt={''} src={whiteFile} />
                <a className='color-black' download href={record.link} style={{ wordBreak: 'break-all' }}>{result}</a>
            </p>
        </div>
    }
    return <><img style={{ width: '16px', height: '20px', paddingBottom: '3px', marginLeft: '-3px' }} alt={''} src={whiteFile} />{result}</>;
};

export const shouldIncreasePendingFiles = (formValues, getState) => {
    const userName = getState().user.userName;
    let usersToPut = formValues.usersToPut;
    if (!usersToPut || usersToPut.length === 0) {
        let oneUser = formValues.userToPut;
        if (!oneUser || oneUser === '')
            oneUser = userName;
        usersToPut = [oneUser];
    }
    if (usersToPut.includes(userName)) {
        if (!formValues.ordered)
            return true;
        return usersToPut[0] === userName;
    }
    return false;
};

export const checkMaxFileSize = (maxSize, language, dispatch, formFile) => {
    if (formFile && formFile.length > 0) {
        let partialSize = 0;
        let error = general[language].maxFileError.replace('{0}', Math.round(maxSize / 1024000));
        for (let i = 0; i < formFile.length; i++) {
            let file = formFile[i];
            let fileSize = 0;
            if (file.size)
                fileSize = file.size;
            else if (file.file)
                fileSize = (file.file.length * 0.75) - 2;
            else if (file.length)
                fileSize = (file.length * 0.75) - 2;
            partialSize += fileSize;
            if (!maxSize) {
                maxSize = 20000000;
                console.log("Default: ", maxSize);
            }
            if (fileSize > maxSize || partialSize > maxSize) {
                error = error.replace('{1}', Math.round(partialSize / 1024000) + '');
                dispatch({ type: 'INFO_SHOW', payload: { info: { message: error, type: 'error' } } });
                return false;
            }
        }
    }
    return true;
}

const getExtension = (fileName) => {
    if (!fileName)
        return '';
    let fileNameParts = fileName.split('.');
    let extension = '';
    if (fileNameParts.length > 1)
        extension = fileNameParts.pop();
    return extension.toLowerCase();
}

export const isPDF = (fileName) => {
    return getExtension(fileName) === 'pdf';
}

export const willBePDF = (fileName, getState) => {
    return getExtension(fileName) === 'pdf' || isPDFExtension(fileName, getState);
}

export const isPDFExtension = (fileName, getState) => {
    const extension = getExtension(fileName);
    const txtExtensions = getState().user.txtExtensions.split(',');
    const imageExtensions = getState().user.imageExtensions.split(',');
    return txtExtensions.includes(extension) || imageExtensions.includes(extension);
}

export const isPDFPasswordError = (err) => {
    return err.name === "PasswordException" || (err.name === 'n' && err.message === 'No password given');
}

export const getStorageCustomization = () => {
    const storageCustomization = window.localStorage.getItem('customization');
    return storageCustomization ? (JSON.parse(storageCustomization)) : {};
}


export const getCustomColors = (newCustomColors, defaultValues = false) => {
    const labels = ['colorPrimary', 'colorSecondary', 'colorPanels', 'colorMenu', 'colorTextPrimary', 'colorTextSecondary', 'colorTextPanels', 'colorTextMenu', 'colorPrimaryOpacity'];
    const customization = defaultValues ? {} : (newCustomColors ? newCustomColors : getStorageCustomization());
    const colors = {}
    labels.map(label => colors[label] = customization && customization[label] ? customization[label] : getComputedStyle(document.documentElement).getPropertyValue(`--default-${label}`))
    return colors;
}

export const getCustomMessages = (language, defaultValues = false) => {
    const labels = ['messageHome', 'messageVerify', 'messageHome2']
    const customization = defaultValues ? {} : getStorageCustomization()
    const messages = {};
    labels.map(label => messages[label] = customization[label] ? customization[label] : auth[language][label]);
    return messages;
}

export const getCustomLogos = (defaultValues = false) => {
    const defaultLogos = { 'base64Logo': newBase64Logo, 'base64LogoDark': newBase64LogoDark }
    const customization = defaultValues ? {} : getStorageCustomization()
    const logos = {};
    Object.keys(defaultLogos).map(label => logos[label] = customization[label] ? customization[label] : defaultLogos[label])
    return logos;
}

export const getDefaultCustom = (language = 'es') => {
    return [getCustomLogos(true), getCustomMessages(language, true), getCustomColors(null, true)]
}

export const showFullMenus = (width) => {
    return width >= minW
}

export const renderLanguageOptions = (handleLanguage, language, style) => {
    const items = [{ key: 'es', label: 'es' }, { key: 'en', label: 'en' }];
    return <Dropdown trigger={['click']} dropdownRender={()=>(
        <Menu items={items} onClick={(evt) => handleLanguage(evt.key === 'es' ? 'en' : 'es')} />
    )}>
        <Text className="size22" style={style} onClick={e => e.preventDefault()}>
            {language}
            <img alt={''} className='margin-left-drop-icon' src={dropDownIcon} />
        </Text>
    </Dropdown>
}

export const renderCertificate = (caDescription = '', push, language, style = {}) => {
    return <button className='unstyled-button' style={style} onClick={() => push(CERTIFICATES_ROUTE)}>
        <div style={{ display: '-webkit-box' }}>
            <img alt={''} src={certificateMenuIcon} />
            <Typography className='color-text-menu' style={{ marginRight: 5 }}>{layout[language].header_certificate.toUpperCase()}</Typography>
            <Typography className='text-title color-black'>{caDescription.toUpperCase()}</Typography>
        </div>
    </button>
}

export const renderInfoTag = (text, align = 'center', style = { width: 90 }) => {
    const putClass = 'tag-info ' + align
    return <div className={putClass} style={style}>
        <Typography className='tag-info-text'>{text}</Typography>
    </div>
}

export const iconCertificate = (key) => {
    if(key !== undefined && key !== null){
        if (key.includes('netco'))
            return netcopkiIcon
        if (key.includes('andes'))
            return andesIcon
        if (key.includes('gse'))
            return gseIcon
    }
    return null
}

export function addMetadata(files, onAdded, metadata, modalShow, language, modalDismiss, dispatch) {
    if (metadata && metadata.length > 0) {
        modalShow({
            modalType: METADATA_MODAL,
            modalProps: { footer: null, title: load_files[language].metadataTitle, onCancel: modalDismiss },
            componentProps: {
                language: language, onSave: (values) => {
                    files.forEach((file) => {
                        file.metadata = values;
                    });
                    onAdded(files);
                    modalDismiss();
                }, metadata: metadata, dispatch: dispatch
            }
        });
    }
    else
        onAdded(files);
}

export async function addDocumentMetadata(metaInfo,uid, dispatch)
{
    const data={...metaInfo};
    data.uid=uid;
    data.idmetadata=data.id;
    if(data.type==='select')
    {
        data.idmetadata=data.metadataID;
        data.idmetadataoptions=data.id;
        delete data.value;
        delete data.metadataID;
    }
    const metadataResponse = await loadingCall(dispatch,()=>netcoServer.newPost('/UserService/addDocumentMetadata', data));
    if(!metadataResponse || !metadataResponse.data || !metadataResponse.data.success)
        console.error("Error agregando metadatos: " + metadataResponse?metadataResponse.data:'');
}

export function getDictionaryMetadata(metadataList, language) {
    const dictionaryMetadata = { metadata: {}, options: {}, optionsText: [] };
    for (let i = 0; i < metadataList.length; i++) {
        const item = metadataList[i];
        dictionaryMetadata.metadata[item.id] = item.name;
        if (item.type === 'select') {
            dictionaryMetadata.options[item.id] = item.options.reduce((acc, option) => {
                acc[option.id] = option.value;
                acc[0] = signed_files[language].selectAll
                return acc;
            }, {});
        }
        if (item.type === 'text') {
            dictionaryMetadata.optionsText.push(item.id)
        }
    }
    return dictionaryMetadata
}

export async function confirmTransferOperationModal(dispatch, numTransfer, language) {
    return new Promise(async function (resolve, reject) {
    //return new Promise((resolve, reject) => {
        await dispatch(modalShow(
            {
                modalType: CONFIRM_TRANSFER_MODAL,
                modalProps: { footer: null, title: load_files[language].confirmTransfer},
                componentProps: {
                    language, numTransfer,
                    send: () => {
                        dispatch(modalDismiss());
                        resolve(true);
                        return
                    },
                    onCancel: () => {
                        dispatch(modalDismiss());
                        reject(infoPayload('error', "Rechazo Transferencia"));
                        return
                    }
                }
            }
        ))
    });
}

export function renderSelectAreaCode() {
    return <Select size={'large'} defaultValue={"57"} style={{ width: '100%', margin: '11px 0 11px 0', textAlign: 'left' }}>
        <Select.Option value="93">(+93) - Afghanistan</Select.Option>
        <Select.Option value="355">(+355) - Albania</Select.Option>
        <Select.Option value="1403">(+1403) - Alberta</Select.Option>
        <Select.Option value="1780">(+1780) - Alberta</Select.Option>
        <Select.Option value="213">(+213) - Algeria</Select.Option>
        <Select.Option value="376">(+376) - Andorra</Select.Option>
        <Select.Option value="244">(+244) - Angola</Select.Option>
        <Select.Option value="1264">(+1264) - Anguilla</Select.Option>
        <Select.Option value="6721">(+6721) - Antarctica (Australian bases)</Select.Option>
        <Select.Option value="1268">(+1268) - Antigua and Barbuda</Select.Option>
        <Select.Option value="54">(+54) - Argentina</Select.Option>
        <Select.Option value="374">(+374) - Armenia</Select.Option>
        <Select.Option value="297">(+297) - Aruba</Select.Option>
        <Select.Option value="247">(+247) - Ascension</Select.Option>
        <Select.Option value="61">(+61) - Australia</Select.Option>
        <Select.Option value="43">(+43) - Austria</Select.Option>
        <Select.Option value="994">(+994) - Azerbaijan</Select.Option>
        <Select.Option value="1242">(+1242) - Bahamas</Select.Option>
        <Select.Option value="973">(+973) - Bahrain</Select.Option>
        <Select.Option value="880">(+880) - Bangladesh</Select.Option>
        <Select.Option value="1246">(+1246) - Barbados</Select.Option>
        <Select.Option value="375">(+375) - Belarus</Select.Option>
        <Select.Option value="32">(+32) - Belgium</Select.Option>
        <Select.Option value="501">(+501) - Belize</Select.Option>
        <Select.Option value="229">(+229) - Benin</Select.Option>
        <Select.Option value="1441">(+1441) - Bermuda</Select.Option>
        <Select.Option value="975">(+975) - Bhutan</Select.Option>
        <Select.Option value="591">(+591) - Bolivia</Select.Option>
        <Select.Option value="387">(+387) - Bosnia and Herzegovina</Select.Option>
        <Select.Option value="267">(+267) - Botswana</Select.Option>
        <Select.Option value="55">(+55) - Brazil</Select.Option>
        <Select.Option value="1250">(+1250) - British Columbia</Select.Option>
        <Select.Option value="1604">(+1604) - British Columbia</Select.Option>
        <Select.Option value="1778">(+1778) - British Columbia</Select.Option>
        <Select.Option value="246">(+246) - British Indian Ocean Territory</Select.Option>
        <Select.Option value="1284">(+1284) - British Virgin Islands</Select.Option>
        <Select.Option value="673">(+673) - Brunei</Select.Option>
        <Select.Option value="359">(+359) - Bulgaria</Select.Option>
        <Select.Option value="226">(+226) - Burkina Faso</Select.Option>
        <Select.Option value="257">(+257) - Burundi</Select.Option>
        <Select.Option value="855">(+855) - Cambodia</Select.Option>
        <Select.Option value="237">(+237) - Cameroon</Select.Option>
        <Select.Option value="238">(+238) - Cape Verde</Select.Option>
        <Select.Option value="1345">(+1345) - Cayman Islands</Select.Option>
        <Select.Option value="236">(+236) - Central African Republic</Select.Option>
        <Select.Option value="235">(+235) - Chad</Select.Option>
        <Select.Option value="56">(+56) - Chile</Select.Option>
        <Select.Option value="86">(+86) - China</Select.Option>
        <Select.Option value="57">(+57) - Colombia</Select.Option>
        <Select.Option value="269">(+269) - Comoros</Select.Option>
        <Select.Option value="243">(+243) - Democratic Republic of the Congo</Select.Option>
        <Select.Option value="242">(+242) - Republic of the Congo</Select.Option>
        <Select.Option value="682">(+682) - Cook Islands</Select.Option>
        <Select.Option value="506">(+506) - Costa Rica</Select.Option>
        <Select.Option value="712">(+712) - Cote dIvoire</Select.Option>
        <Select.Option value="385">(+385) - Croatia</Select.Option>
        <Select.Option value="53">(+53) - Cuba</Select.Option>
        <Select.Option value="357">(+357) - Cyprus</Select.Option>
        <Select.Option value="420">(+420) - Czech Republic</Select.Option>
        <Select.Option value="45">(+45) - Denmark</Select.Option>
        <Select.Option value="253">(+253) - Djibouti</Select.Option>
        <Select.Option value="1767">(+1767) - Dominica</Select.Option>
        <Select.Option value="1809">(+1809) - Dominican Republic</Select.Option>
        <Select.Option value="1829">(+1829) - Dominican Republic</Select.Option>
        <Select.Option value="670">(+670) - East Timor</Select.Option>
        <Select.Option value="593">(+593) - Ecuador</Select.Option>
        <Select.Option value="20">(+20) - Egypt</Select.Option>
        <Select.Option value="503">(+503) - El Salvador</Select.Option>
        <Select.Option value="240">(+240) - Equatorial Guinea</Select.Option>
        <Select.Option value="291">(+291) - Eritrea</Select.Option>
        <Select.Option value="34">(+34) - España</Select.Option>
        <Select.Option value="372">(+372) - Estonia</Select.Option>
        <Select.Option value="251">(+251) - Ethiopia</Select.Option>
        <Select.Option value="500">(+500) - Falkland Islands</Select.Option>
        <Select.Option value="298">(+298) - Faroe Islands</Select.Option>
        <Select.Option value="679">(+679) - Fiji</Select.Option>
        <Select.Option value="358">(+358) - Finland</Select.Option>
        <Select.Option value="33">(+33) - France</Select.Option>
        <Select.Option value="594">(+594) - French Guiana</Select.Option>
        <Select.Option value="689">(+689) - French Polynesia</Select.Option>
        <Select.Option value="241">(+241) - Gabon</Select.Option>
        <Select.Option value="220">(+220) - Gambia</Select.Option>
        <Select.Option value="995">(+995) - Georgia</Select.Option>
        <Select.Option value="49">(+49) - Germany</Select.Option>
        <Select.Option value="233">(+233) - Ghana</Select.Option>
        <Select.Option value="350">(+350) - Gibraltar</Select.Option>
        <Select.Option value="30">(+30) - Greece</Select.Option>
        <Select.Option value="299">(+299) - Greenland</Select.Option>
        <Select.Option value="1473">(+1473) - Grenada</Select.Option>
        <Select.Option value="1671">(+1671) - Guam</Select.Option>
        <Select.Option value="502">(+502) - Guatemala</Select.Option>
        <Select.Option value="224">(+224) - Guinea</Select.Option>
        <Select.Option value="245">(+245) - Guinea-Bissau</Select.Option>
        <Select.Option value="592">(+592) - Guyana</Select.Option>
        <Select.Option value="509">(+509) - Haiti</Select.Option>
        <Select.Option value="504">(+504) - Honduras</Select.Option>
        <Select.Option value="852">(+852) - Hong Kong</Select.Option>
        <Select.Option value="36">(+36) - Hungary</Select.Option>
        <Select.Option value="354">(+354) - Iceland</Select.Option>
        <Select.Option value="91">(+91) - India</Select.Option>
        <Select.Option value="62">(+62) - Indonesia</Select.Option>
        <Select.Option value="964">(+964) - Iraq</Select.Option>
        <Select.Option value="98">(+98) - Iran</Select.Option>
        <Select.Option value="353">(+353) - Ireland (Eire)</Select.Option>
        <Select.Option value="972">(+972) - Israel</Select.Option>
        <Select.Option value="39">(+39) - Italy</Select.Option>
        <Select.Option value="1876">(+1876) - Jamaica</Select.Option>
        <Select.Option value="81">(+81) - Japan</Select.Option>
        <Select.Option value="962">(+962) - Jordan</Select.Option>
        <Select.Option value="254">(+254) - Kenya</Select.Option>
        <Select.Option value="686">(+686) - Kiribati</Select.Option>
        <Select.Option value="965">(+965) - Kuwait</Select.Option>
        <Select.Option value="996">(+996) - Kyrgyzstan</Select.Option>
        <Select.Option value="856">(+856) - Laos</Select.Option>
        <Select.Option value="371">(+371) - Latvia</Select.Option>
        <Select.Option value="961">(+961) - Lebanon</Select.Option>
        <Select.Option value="266">(+266) - Lesotho</Select.Option>
        <Select.Option value="231">(+231) - Liberia</Select.Option>
        <Select.Option value="218">(+218) - Libya</Select.Option>
        <Select.Option value="423">(+423) - Liechtenstein</Select.Option>
        <Select.Option value="370">(+370) - Lithuania</Select.Option>
        <Select.Option value="352">(+352) - Luxembourg</Select.Option>
        <Select.Option value="853">(+853) - Macau</Select.Option>
        <Select.Option value="389">(+389) - Republic of Macedonia</Select.Option>
        <Select.Option value="261">(+261) - Madagascar</Select.Option>
        <Select.Option value="265">(+265) - Malawi</Select.Option>
        <Select.Option value="60">(+60) - Malaysia</Select.Option>
        <Select.Option value="960">(+960) - Maldives</Select.Option>
        <Select.Option value="223">(+223) - Mali</Select.Option>
        <Select.Option value="356">(+356) - Malta</Select.Option>
        <Select.Option value="1204">(+1204) - Manitoba</Select.Option>
        <Select.Option value="692">(+692) - Marshall Islands</Select.Option>
        <Select.Option value="596">(+596) - Martinique</Select.Option>
        <Select.Option value="222">(+222) - Mauritania</Select.Option>
        <Select.Option value="230">(+230) - Mauritius</Select.Option>
        <Select.Option value="52">(+52) - Mexico</Select.Option>
        <Select.Option value="691">(+691) - Federated States of Micronesia</Select.Option>
        <Select.Option value="373">(+373) - Moldova</Select.Option>
        <Select.Option value="377">(+377) - Monaco</Select.Option>
        <Select.Option value="976">(+976) - Mongolia</Select.Option>
        <Select.Option value="382">(+382) - Montenegro</Select.Option>
        <Select.Option value="1664">(+1664) - Montserrat</Select.Option>
        <Select.Option value="212">(+212) - Morocco</Select.Option>
        <Select.Option value="258">(+258) - Mozambique</Select.Option>
        <Select.Option value="95">(+95) - Myanmar</Select.Option>
        <Select.Option value="264">(+264) - Namibia</Select.Option>
        <Select.Option value="674">(+674) - Nauru</Select.Option>
        <Select.Option value="31">(+31) - Netherlands</Select.Option>
        <Select.Option value="599">(+599) - Netherlands Antilles</Select.Option>
        <Select.Option value="977">(+977) - Nepal</Select.Option>
        <Select.Option value="1506">(+1506) - New Brunswick</Select.Option>
        <Select.Option value="687">(+687) - New Caledonia</Select.Option>
        <Select.Option value="64">(+64) - New Zealand</Select.Option>
        <Select.Option value="1709">(+1709) - Newfoundland</Select.Option>
        <Select.Option value="505">(+505) - Nicaragua</Select.Option>
        <Select.Option value="227">(+227) - Niger</Select.Option>
        <Select.Option value="234">(+234) - Nigeria</Select.Option>
        <Select.Option value="683">(+683) - Niue</Select.Option>
        <Select.Option value="6723">(+6723) - Norfolk Island</Select.Option>
        <Select.Option value="850">(+850) - North Korea</Select.Option>
        <Select.Option value="1670">(+1670) - Northern Mariana Islands</Select.Option>
        <Select.Option value="1867">(+1867) - Northwest Territories</Select.Option>
        <Select.Option value="47">(+47) - Norway</Select.Option>
        <Select.Option value="1902">(+1902) - Nova Scotia</Select.Option>
        <Select.Option value="968">(+968) - Oman</Select.Option>
        <Select.Option value="1416">(+1416) - Ontario</Select.Option>
        <Select.Option value="1519">(+1519) - Ontario</Select.Option>
        <Select.Option value="1613">(+1613) - Ontario</Select.Option>
        <Select.Option value="1647">(+1647) - Ontario</Select.Option>
        <Select.Option value="1705">(+1705) - Ontario</Select.Option>
        <Select.Option value="1807">(+1807) - Ontario</Select.Option>
        <Select.Option value="1905">(+1905) - Ontario</Select.Option>
        <Select.Option value="92">(+92) - Pakistan</Select.Option>
        <Select.Option value="680">(+680) - Palau</Select.Option>
        <Select.Option value="507">(+507) - Panama</Select.Option>
        <Select.Option value="675">(+675) - Papua New Guinea</Select.Option>
        <Select.Option value="595">(+595) - Paraguay</Select.Option>
        <Select.Option value="51">(+51) - Peru</Select.Option>
        <Select.Option value="63">(+63) - Philippines</Select.Option>
        <Select.Option value="48">(+48) - Poland</Select.Option>
        <Select.Option value="351">(+351) - Portugal</Select.Option>
        <Select.Option value="974">(+974) - Qatar</Select.Option>
        <Select.Option value="1418">(+1418) - Quebec</Select.Option>
        <Select.Option value="1450">(+1450) - Quebec</Select.Option>
        <Select.Option value="1514">(+1514) - Quebec</Select.Option>
        <Select.Option value="1819">(+1819) - Quebec</Select.Option>
        <Select.Option value="262">(+262) - Reunion</Select.Option>
        <Select.Option value="40">(+40) - Romania</Select.Option>
        <Select.Option value="7">(+7) - Russia</Select.Option>
        <Select.Option value="250">(+250) - Rwanda</Select.Option>
        <Select.Option value="290">(+290) - Saint Helena</Select.Option>
        <Select.Option value="1869">(+1869) - Saint Kitts and Nevis</Select.Option>
        <Select.Option value="1758">(+1758) - Saint Lucia</Select.Option>
        <Select.Option value="590">(+590) - Saint Martin (French side)</Select.Option>
        <Select.Option value="508">(+508) - Saint Pierre and Miquelon</Select.Option>
        <Select.Option value="685">(+685) - Samoa</Select.Option>
        <Select.Option value="239">(+239) - Sao Tome and Principe</Select.Option>
        <Select.Option value="1306">(+1306) - Saskatchewan</Select.Option>
        <Select.Option value="966">(+966) - Saudi Arabia</Select.Option>
        <Select.Option value="221">(+221) - Senegal</Select.Option>
        <Select.Option value="381">(+381) - Serbia</Select.Option>
        <Select.Option value="248">(+248) - Seychelles</Select.Option>
        <Select.Option value="232">(+232) - Sierra Leone</Select.Option>
        <Select.Option value="65">(+65) - Singapore</Select.Option>
        <Select.Option value="421">(+421) - Slovakia</Select.Option>
        <Select.Option value="386">(+386) - Slovenia</Select.Option>
        <Select.Option value="677">(+677) - Solomon Islands</Select.Option>
        <Select.Option value="252">(+252) - Somalia</Select.Option>
        <Select.Option value="27">(+27) - South Africa</Select.Option>
        <Select.Option value="82">(+82) - South Korea</Select.Option>
        <Select.Option value="211">(+211) - South Sudan</Select.Option>
        <Select.Option value="94">(+94) - Sri Lanka</Select.Option>
        <Select.Option value="249">(+249) - Sudan</Select.Option>
        <Select.Option value="597">(+597) - Suriname</Select.Option>
        <Select.Option value="268">(+268) - Swaziland</Select.Option>
        <Select.Option value="46">(+46) - Sweden</Select.Option>
        <Select.Option value="41">(+41) - Switzerland</Select.Option>
        <Select.Option value="963">(+963) - Syria</Select.Option>
        <Select.Option value="886">(+886) - Taiwan</Select.Option>
        <Select.Option value="992">(+992) - Tajikistan</Select.Option>
        <Select.Option value="255">(+255) - Tanzania</Select.Option>
        <Select.Option value="66">(+66) - Thailand</Select.Option>
        <Select.Option value="228">(+228) - Togo</Select.Option>
        <Select.Option value="690">(+690) - Tokelau</Select.Option>
        <Select.Option value="676">(+676) - Tonga</Select.Option>
        <Select.Option value="1868">(+1868) - Trinidad and Tobago</Select.Option>
        <Select.Option value="216">(+216) - Tunisia</Select.Option>
        <Select.Option value="90">(+90) - Turkey</Select.Option>
        <Select.Option value="993">(+993) - Turkmenistan</Select.Option>
        <Select.Option value="1649">(+1649) - Turks and Caicos Islands</Select.Option>
        <Select.Option value="688">(+688) - Tuvalu</Select.Option>
        <Select.Option value="256">(+256) - Uganda</Select.Option>
        <Select.Option value="380">(+380) - Ukraine</Select.Option>
        <Select.Option value="971">(+971) - United Arab Emirates</Select.Option>
        <Select.Option value="44">(+44) - United Kingdom</Select.Option>
        <Select.Option value="1">(+1) - United States of America</Select.Option>
        <Select.Option value="598">(+598) - Uruguay</Select.Option>
        <Select.Option value="998">(+998) - Uzbekistan</Select.Option>
        <Select.Option value="678">(+678) - Vanuatu</Select.Option>
        <Select.Option value="58">(+58) - Venezuela</Select.Option>
        <Select.Option value="84">(+84) - Vietnam</Select.Option>
        <Select.Option value="1340">(+1340) - U.S. Virgin Islands</Select.Option>
        <Select.Option value="681">(+681) - Wallis and Futuna</Select.Option>
        <Select.Option value="970">(+970) - West Bank</Select.Option>
        <Select.Option value="967">(+967) - Yemen</Select.Option>
        <Select.Option value="260">(+260) - Zambia</Select.Option>
        <Select.Option value="263">(+263) - Zimbabwe</Select.Option>
    </Select>
}