import axios from 'axios';
import { trackPromise } from "react-promise-tracker";
const netcoServer = axios.create({
  baseURL: window.netcourlid + "/rest",
  headers: {
    common: {
      'Content-Type': 'application/json',
    }
  },
  transformResponse: [
    (data) => {
      let resp
      try {
        resp = JSON.parse(data, (key, value, context) => {
          if (key === "serial") {
            // Ignore the value because it has already lost precision
            return context.source;
          }
          return value;
        });
      } catch (error) {
        throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`)
      }
      return resp
    }
  ],
  withCredentials: true,
});
const netcoServerBlob = axios.create({
  baseURL: window.netcourlid + "/rest",
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  withCredentials: true,
  responseType: 'blob'
});

netcoServer.newPost = (url, formValues) => trackPromise(netcoServer.post(url, formValues));
netcoServer.newPostBlob = (url, formValues) => trackPromise(netcoServerBlob.post(url, formValues));

netcoServer.interceptors.response.use((response) => {
  return (response)
}, error => {
  if (error.response && error.response.status === 500)
    error.response.data = { detail: "Error 500" };
  return (error.response)
});

export default netcoServer;